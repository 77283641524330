import type { AppProps } from 'next/app'
import Head from 'next/head'
import { GoogleAnalytics } from "nextjs-google-analytics";
import '@tawn33y/gigsflow-core/dist/index.css'
import { config as faConfig, dom } from "@fortawesome/fontawesome-svg-core";
import { ErrorBoundary } from "@sentry/react";
import { AppContextProvider } from '@tawn33y/gigsflow-core'
import { config } from '@/config'
import { init } from '@/init';
import { ErrorFallback } from '@/components/ErrorFallback'

// @see https://github.com/FortAwesome/react-fontawesome/issues/284#issuecomment-691707228
faConfig.autoAddCss = false;

init();

export default function App({ Component, pageProps }: AppProps) {
  const { env, mediaBaseUrl, googleMapsApiKey, plausibleAuthToken, plausibleSiteId, routerType, gaMeasurementId } = config;

  return (
    <ErrorBoundary fallback={ErrorFallback}>
      {env === 'production' && (
        // @see: https://nextjs.org/docs/messages/next-script-for-ga
        <GoogleAnalytics
          trackPageViews
          gaMeasurementId={gaMeasurementId}
        />
      )}

      <Head>
        <style>{dom.css()}</style>

        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta httpEquiv="Content-Type" content="text/html; charset=iso-8859-1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link rel="mask-icon" href="favicons/safari-pinned-tab.svg" color="#97939e" />
        <link rel="shortcut icon" href="/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#9f00a7" />
        <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="color-scheme" content="dark light" />

        <title>Gigsflow</title>

        {env === 'production' && (
          <script defer data-domain={plausibleSiteId} src="https://plausible.io/js/script.js"></script>
        )}
      </Head>

      <AppContextProvider
        colorScheme='light'
        mediaBaseUrl={mediaBaseUrl}
        googleMapsApiKey={googleMapsApiKey}
        plausibleAuthToken={plausibleAuthToken}
        plausibleSiteId={plausibleSiteId}
        routerType={routerType}
        onChangeColorScheme={() => {}}
      >
        <Component {...pageProps} />
      </AppContextProvider>
    </ErrorBoundary>
  )
}
