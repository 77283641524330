import { FallbackRender } from "@sentry/nextjs";
import { ErrorPage } from "@tawn33y/gigsflow-core";
import { config } from "@/config";

export const ErrorFallback: FallbackRender = ({ error }) => {
  const { env } = config;

  return (
    <ErrorPage
      error={error}
      showStackTrace={env !== 'production'}
    />
  );
}
