const env = process.env['NODE_ENV'];
const mediaBaseUrl = process.env['NEXT_PUBLIC_MEDIA_BASE_URL'];
const googleMapsApiKey = process.env['NEXT_PUBLIC_GOOGLE_MAPS_API_KEY'];
const plausibleAuthToken = process.env['NEXT_PUBLIC_PLAUSIBLE_AUTH_TOKEN'];
const plausibleSiteId = process.env['NEXT_PUBLIC_PLAUSIBLE_SITE_ID'];
const sentryDSN = process.env['NEXT_PUBLIC_SENTRY_DSN'];
const gaMeasurementId = process.env['NEXT_PUBLIC_GA_MEASUREMENT_ID'];

if (!env || !['production', 'development', 'test'].includes(env) || !mediaBaseUrl || !googleMapsApiKey || !plausibleAuthToken || !plausibleSiteId || !sentryDSN || !gaMeasurementId) {
  throw new Error('Missing env setup');
}

type Config = Readonly<{
  env: 'production' | 'development' | 'test';
  routerType: 'NEXTJS';
  mediaBaseUrl: string;
  googleMapsApiKey: string;
  plausibleAuthToken: string;
  plausibleSiteId: string;
  sentryDSN: string;
  gaMeasurementId: string;
}>

export const config: Config = {
  env,
  routerType: 'NEXTJS',
  mediaBaseUrl,
  googleMapsApiKey,
  plausibleAuthToken,
  plausibleSiteId,
  sentryDSN,
  gaMeasurementId,
};
